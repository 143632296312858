import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cutText'
})
export class CutTextPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    const maxSymbols = 140;
    const moreThanMaxSymbols = value.length > 140;

    if (moreThanMaxSymbols) {
      const textArr = value.substr(0, 140).split(' ');
      textArr.pop();
      const text = textArr.join(' ');

      return `${text}...`;
    }

    return value;
  }

}

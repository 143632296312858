import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CompanyComponent } from './company/company.component';
import { CareersComponent } from './careers/careers.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { JobPositionComponent } from './job-position/job-position.component';

const routes: Routes = [
  { path: 'company', component: CompanyComponent, data: { title: 'Company'} },
  { path: 'careers', component: CareersComponent, data: { title: 'Careers'} },
  { path: 'careers/:id', component: JobPositionComponent, data: { title: 'Job Position'} },
  { path: 'contact-us', component: ContactUsComponent, data: { title: 'Contact us'} },
  { path: '**', redirectTo: '/company' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

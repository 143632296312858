import { Component } from '@angular/core';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent {

  areBenefitsExpanded = false;

  expandBenefits() {
    this.areBenefitsExpanded = true;
  }

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { mergeMap, map, filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    // this is used to change the page title according to which page are we currently at
    // https://toddmotto.com/dynamic-page-titles-angular-2-router-events
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      // By returning a new Object into our stream (this.activatedRoute) we essentially swap what
      // we’re observing - so at this point we are only running the .map() should the filter()
      // successfully return us the event type of NavigationEnd.
      map(() => this.activatedRoute),
      // while loop to traverse over the state tree to find the last activated route,
      // and then return it to the stream
      map(route => {
        let r = route;

        while (r.firstChild) {
          r = r.firstChild;
        }

        return r;
      }),
      // Doing this allows us to essentially dive into the children property of the routes config
      // to fetch the corresponding page title(s). After this, we want two more operators
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data))
      .subscribe(event => {
        // Change the title of the screen
        const title = event['title'] ? `${event['title']} | Quant Azimuth` : `Quant Azimuth`;
        this.titleService.setTitle(title);
      });
  }
}

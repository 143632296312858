export class JobPosition {
  id: string;
  shortName: string;
  fullName: string;
  descriptionParagraphs: string[];
  generalResponsibilities: string[];
  requirements: string[];
  others: string[];
  languages: string[];
}

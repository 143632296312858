import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { JobPosition } from './job-position.model';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-job-position',
  templateUrl: './job-position.component.html',
  styleUrls: ['./job-position.component.scss']
})
export class JobPositionComponent implements OnInit {

  @ViewChild('uploadInput', { read: ElementRef }) uploadInput: ElementRef;
  @ViewChild('applicationForm') form: NgForm;

  position: JobPosition = new JobPosition();
  fileInfo: string;
  applicationParams = {
    name: '',
    email: '',
    cv: [],
    message: ''
  };

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      return this.http.get<JobPosition[]>('/assets/data/job-positions.json')
        .subscribe(data => {
          this.position = data.find(position => position.id === params.get('id'));

          const title = `${this.position.fullName} | Careers | Quant Azimuth`;
          this.titleService.setTitle(title);
        });
    });
  }

  openFileBrowser() {
    this.uploadInput.nativeElement.click();
  }

  apply() {
    console.log(this.form);
    if (this.form.invalid) { return; }
    console.log(this.applicationParams);
  }

  onFileChange(event) {
    const name = this.uploadInput.nativeElement.value.split(/\\|\//).pop();
    const truncated = name.length > 40 ? name.substr(name.length - 40) : name;
    this.fileInfo = truncated;

    this.applicationParams.cv = event.target.files;
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  @ViewChild('contactForm') form: NgForm;

  contactParams = {
    name: '',
    email: '',
    message: ''
  };

  constructor() { }

  ngOnInit() {
  }

  send() {
    console.log(this.form);
    if (this.form.invalid) { return; }
    console.log(this.contactParams);
  }

}
